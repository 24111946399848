import React from "react";
import { IAccordionItem } from "../../types/IAccordionItem";
import styles from './accordion.module.scss';
import AccordionItem from "../accordion-item/accordion-item";
import itemStyles from '../accordion-item/accordion-item.module.scss';

interface AccordionProps {
    items: IAccordionItem[];
    openState?: 'all' | 'first' | 'none';
    className?: string;
}
const Accordion: React.FC<AccordionProps> = ({ items, openState = 'none', className }) => {
    const setOpen = (index: number): boolean => {
        if (openState === 'none')
            return false;
        else if (openState === 'first')
            return index === 0;
        else return true;
    }
    return (
        <>
            <ul className={`${styles.container} ${className}`}>
                {items && items.map((item, index) => 
                    <AccordionItem className={className?.includes(styles.print) ? itemStyles.print : ''} defaultState={setOpen(index)} key={index} item={item}/>
                )}
            </ul>
        </>
    )
}
export default Accordion;