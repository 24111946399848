import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { IRecipe } from "../../types/IRecipe";
import ImageContainer from "../../components/image/image-container";
import ProductCard from "../../components/product-card/product-card";
import ILabels from "../../types/ILabels";
import { IRecipeImage } from "../../types/IRecipeImage";
import Accordion from "../../components/accordion/accordion";
import { IAccordionItem } from "../../types/IAccordionItem";
import imageStyles from '../../components/image/image-container.module.scss';
import productStyle from '../../components/product-card/product-card.module.scss';
import accordionStyle from '../../components/accordion/accordion.module.scss';
import styles from './print-recipe-page.module.scss';

const PrintRecipePage: React.FC = () => {
  const [recipe, setRecipe] = useState<IRecipe | null>(null);
  const [labels, setLabels] = useState<ILabels | null>(null);
  const [recipeImage, setRecipeImage] = useState<IRecipeImage | null>(null);
  const [accordionItems, setAccordionItems] = useState<IAccordionItem[] | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const waitForImagesToLoad = () => {
    const images = Array.from(document.images);

    return Promise.all(
        images.map((img) => 
            img.complete 
                ? Promise.resolve() 
                : new Promise((resolve) => {
                    img.onload = resolve;
                    img.onerror = resolve;
                })
        )
    );
};

  useEffect(() => {
    const storedData = sessionStorage.getItem('printData');

    if (storedData) {
      try {
        const data = JSON.parse(storedData);

        if (data.recipe) {
          setRecipe(data.recipe);
          setRecipeImage(data.recipe.Images?.find((x: IRecipeImage) => x.Type === "Recipe") ?? null);
        }

        if (data.labels) {
          setLabels(data.labels);
        }

        waitForImagesToLoad().then(() => setTimeout(() => setIsLoaded(true), 300));

      } catch (error) {
        console.error("Could not read printData from sessionStorage:", error);
      }
    }

  }, [])

  useEffect(() => {
    if (recipe?.Ingredients)
      setAccordionItems([{ title: labels?.ingredientsLabel ? labels.ingredientsLabel : "Ingredients", text: recipe.Ingredients } as IAccordionItem])
  }, [labels, recipe])

  useEffect(() => {
    if (isLoaded) {
      window.print();
      setTimeout(() => window.close(), 300);
    } 
  }, [isLoaded])

  return (
    <>{recipe !== null && <div className={styles.showOnPrint}>
      <ImageContainer className={imageStyles.print} url={recipeImage?.Url} alt={recipe.Name} />
      <h5 className={styles.title}>{recipe.Name}</h5>
      <div className={styles.container}>
        {recipe.RelatedProducts?.length > 0 &&
          <div className={styles.leftColumn}>
            <h5 className={styles.productsHeading}>{labels?.productsLabel ?? 'Products'}</h5>
            {recipe.RelatedProducts.map((product) =>
              <ProductCard
                className={productStyle.print}
                key={product.Id}
                product={product}
                viewProductLabel={labels?.viewProductLabel}
                piecesPerCartonLabel={labels?.piecesPerCartonLabel}
              />)}

          </div>}
        <div className={styles.rightColumn}>
          <div className={styles.bodyContainer}>
            <div className={styles.body}>
              {accordionItems && (<Accordion className={accordionStyle.print} openState="all" items={accordionItems} />)}
            </div>
          </div>

          <div className={styles.instructionsContainer} >
            {recipe.Instructions && recipe.Instructions.map((instructions, c_index) =>
              <>
                {instructions.Instruction &&
                  <>
                    <span key={`heading${c_index}`} className={styles.instructionsHeading}>{instructions.Heading ? instructions.Heading : labels?.instructionsLabel ?? "Instructions"}</span>
                    <ol className={styles.instructionList} key={instructions.Heading + c_index}>
                      {instructions.Instruction.map((instruction, i_index) =>
                        <li className={styles.instruction} key={i_index}>
                          {instruction.Short}
                        </li>
                      )}
                    </ol>
                  </>}
              </>)}
          </div>
        </div>
      </div>
    </div>}
    </>
  );
};

export default PrintRecipePage;