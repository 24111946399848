import React, { CSSProperties, ReactNode, useEffect, useMemo, useState } from 'react'
import styles from './background-animation.module.scss'
import { IBackgroundAnimation } from '../../types/IBackgroundAnimation';
import Loading from '../loading/loading';
import IToolTipLink from '../../types/ITooltipLink';
import Tooltip from '../tooltip/tooltip';
import tooltipStyles from '../tooltip/tooltip.module.scss';
import { getArrayLengthByDuplication } from '../../helpers/helper';
import { useNavigate } from 'react-router-dom';

interface BackgroundAnimationProps {
    backgroundAnimation?: IBackgroundAnimation;
    children?: ReactNode;
    className?: string;
    logoUrl?: string;
    loadingLogoUrl?: string;
    useBackgroundFade?: boolean;
    flexibleBody?: boolean;
    loading: boolean;
    loadingText?: string;
    toolTips?: IToolTipLink[];
    animateOnPropChanged?: any;
    loadingDelay?: number;
    onLogoClick: () => void;
}

const BackgroundAnimation: React.FC<BackgroundAnimationProps> =
    ({ backgroundAnimation,
        children,
        className,
        logoUrl,
        loadingLogoUrl,
        useBackgroundFade = false,
        flexibleBody = false,
        loading,
        toolTips,
        loadingText,
        animateOnPropChanged = null,
        loadingDelay = 1,
        onLogoClick
         }) => {
        const [fading, setFading] = useState(false);
        const [faded, setFaded] = useState(false);
        const [loaded, setLoaded] = useState(false);
        const [loadEffectDone, setLoadEffectDone] = useState(false);
        const [cssSwitch, setCssSwitch] = useState<string>(''); //used to switch images on tiles.. remove?
        const [tilePosition, setTilePosition] = useState<number>(3);
        const [tilePositionClass, setTilePositionClass] = useState<string>('');
        const [rotationDegrees, setRotationDegrees] = useState<number | null>(null);
        const [rotationContainerStyle, setRotationContainerStyle] = useState<CSSProperties>({});
        const [rotationTileStyle, setRotationTileStyle] = useState<CSSProperties>({});
        const [stickyTooltip, setStickyTooltip] = useState(false);
        
        const getIndexedCssClass = (index: number) => `${styles[`img_tile_${index}`]}`;

        const onLogoClicked = () => {
            onLogoClick();
        }


        useEffect(() => {
            let interval: NodeJS.Timeout | null = null;
            if (!loaded && className?.includes(styles.recipeList)) {
                setTilePosition(3);
                if (rotationDegrees === null) setRotationDegrees(0);
                interval = setInterval(() => {
                    setRotationDegrees(prev => prev !== null ? prev + 45 : 0);
                }, 1500);
            }
            else {
                setTilePosition(0);
                setRotationContainerStyle({});
            }

            return () => {
                if (interval) {
                    clearInterval(interval);
                }
            };
        }, [className, loaded, rotationDegrees]);

        useEffect(() => {
            setTilePositionClass(tilePosition !== 0 ? `${styles[`pos_${tilePosition}`]}` : '');
        }, [tilePosition])

        useEffect(() => {
            if (rotationDegrees !== null){
                setRotationContainerStyle(rotationDegrees === 0 ?
                    {
                        position: 'absolute',
                        width: '600px',
                        height: '600px',
                        left: '50%',
                        top: '50%',
                        transform: `translate(-50%, -50%) rotate(${rotationDegrees}deg)`
                    }
                    :
                    {
                        position: 'absolute',
                        width: '600px',
                        height: '600px',
                        left: '50%',
                        top: '50%',
                        transition: 'transform 700ms',
                        transform: `translate(-50%, -50%) rotate(${rotationDegrees}deg)`
                    });

                setRotationTileStyle(rotationDegrees === 0 ?
                    {
                        transform: `translate(-50%, -50%) rotate(${rotationDegrees}deg)`
                    }
                    :
                    {
                        transition: 'transform 700ms',
                        transform: `translate(-50%, -50%) rotate(-${rotationDegrees}deg)`
                    })
                }
                
        }, [rotationDegrees])

        useEffect(() => {
            if (!loading)
                setLoaded(loadEffectDone);
        }, [loadEffectDone, loading])

        useEffect(() => {
            const timer = setTimeout(() => {
                setFading(true);
            }, 1000);
            return () => clearTimeout(timer);
        }, [])

        useEffect(() => {
                const timer = setTimeout(() => {
                    setLoadEffectDone(true);
                }, loadingDelay);
                return () => clearTimeout(timer);
        }, [loadingDelay])

        useEffect(() => {
            if (fading) {
                const timer = setTimeout(() => setFaded(true), 1000);
                return () => clearTimeout(timer);
            }
        }, [fading])

        useEffect(() => {
            if (className?.includes("recipeList")) {
                setStickyTooltip(true);
            }
        }, [className])

        const setTileClassNames = (index: number) => {
            return `${styles.img_tile} ${getIndexedCssClass(index)} ${tilePositionClass && tilePositionClass}`
        }

        const imageTiles = useMemo(() => getArrayLengthByDuplication(backgroundAnimation?.imageUrls, 10), [backgroundAnimation]);

        return (
            <>
                <div className={`${styles.container} ${className} ${loaded ? styles.loaded : ''}`}>
                    {useBackgroundFade && !faded && (<div className={`${styles.backgroundFadeContainer} ${fading ? styles.fadeout : ''}`}>
                        {backgroundAnimation?.startupImageUrl && <img className={styles.background_image} src={backgroundAnimation.startupImageUrl} alt="Background" />}
                        {loadingLogoUrl && <img className={styles.alternateLogo} src={loadingLogoUrl} alt="Logo" />}
                        <div className={styles.overlay} />
                    </div>)}
                    <div style={rotationContainerStyle}>
                    {imageTiles && imageTiles.map((url, index) => (
                        <picture>
                            <source srcSet={`${process.env.REACT_APP_ANIMATION_THUMBNAIL_URL_DESKTOP}${url}`} media='(min-width: 768px)'/>
                            <img loading='lazy' className={setTileClassNames(index)} style={rotationTileStyle} key={index} src={`${process.env.REACT_APP_ANIMATION_THUMBNAIL_URL}${url}`} alt={`background ${index}`} />
                        </picture>
                    ))}</div>
                    <div className={styles.logoContainer}>
                        {loaded && logoUrl && (
                            <img onClick={onLogoClicked} className={styles.logo} src={logoUrl} alt="Logo" />
                        )}
                    </div>
                    {!loaded && <Loading logoUrl={loadingLogoUrl ?? ''} text={loadingText} />}
                    <div className={`${styles.content} ${flexibleBody ? `${styles.flexible}` : ''} ${!loaded ? styles.loading : ''}`}>
                            {loaded && children && (children)}
                    </div>
                    {toolTips && <Tooltip className={stickyTooltip ? tooltipStyles.recipeList : ''} links={toolTips}></Tooltip>}

                </div>
            </>
        );
    }

export default BackgroundAnimation;