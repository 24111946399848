import React, { ReactNode, useEffect, useState } from 'react';
import styles from './slide-over.module.scss'
import { ReactComponent as PrevIcon } from './../../assets/icons/arrow-left.svg';



interface SlideOverProps {
    children?: ReactNode;
    buttonBackLabel?: string;
    onClose: () => void;
    ghostCloseButton?: boolean;
}

const SlideOver: React.FC<SlideOverProps> = ({ children, buttonBackLabel, onClose, ghostCloseButton = true }) => {
    const [open, setOpen] = useState(false);

    const closeModal = () => {
        setOpen(false);
        const timer = setTimeout(() => onClose(), 500);
        return () => clearTimeout(timer);
    }

    useEffect(() => {
        setOpen(true);
    }, [])

    return (
        <>
            <div className={`${styles.overlay} ${open ? styles.open : ''}`} onClick={closeModal}></div>
            <div className={`${styles.container} ${open ? styles.open : ''}`}>
            <button className={`${styles.closeButton} btn btn-small btn-secondary ${ghostCloseButton ? 'btn-ghost' : ''}`} onClick={closeModal}>
                        <PrevIcon />
                        <span>{buttonBackLabel ?? 'Back'}</span>
                </button>
               {children && children}
            </div>
        </>
    )
}

export default SlideOver;