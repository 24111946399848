import React, { useState } from "react";
import IToolTipLink from "../../types/ITooltipLink";
import styles from './tooltip.module.scss';
import { ReactComponent as VerticalDots } from '../../assets/icons/vertical-dots.svg';


interface TooltipProps {
    links: IToolTipLink[];
    className?: string;
}

const Tooltip: React.FC<TooltipProps> = ({ links, className }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggleTooltip = () => {
        setTooltipOpen(prev => !prev);
    }
    return (
        <>
            {tooltipOpen &&
                <>
                    <div className={styles.overlay} onClick={toggleTooltip}></div>
                    <div className={`${styles.container} ${className}`}>
                        <div className={styles.arrow}></div>
                        {links.length > 0 && links.map((link, index) =>
                            <a target="_blank" className={styles.link} key={index} href={link.href} rel="noopener noreferrer">{link.title}</a>)}
                    </div>
                </>}
            <button className={`btn btn-small btn-secondary ${styles.tooltipBtn}`} onClick={toggleTooltip}><VerticalDots /></button>
        </>
    )
}

export default Tooltip;