import React, { useEffect, useRef } from "react";
import styles from './signup-card.module.scss';
import { IHubSpotProps } from "../../types/IHubSpotProps";
import { insertValueInTemplate } from "../../helpers/helper";
import { ReactComponent as LockIcon } from '../../assets/icons/lock.svg'

interface SignUpCardProps {
    hubSpotProps: IHubSpotProps;
    numberOfHits?: number;
    onButtonClicked: () => void;
}

const SignUpCard: React.FC<SignUpCardProps> = ({ hubSpotProps, numberOfHits = 0, onButtonClicked }) => {

   const handleOnButtonClicked = () => onButtonClicked();

   let bodyText = undefined;
   if (hubSpotProps?.unlockRecipesBody) 
        bodyText = insertValueInTemplate(hubSpotProps.unlockRecipesBody ?? '', numberOfHits) ?? '';
    else bodyText = 'All recipes will be available upon mail registration';

    return (
        <>
           <div className={styles.container}>
            <div className={styles.iconContainer}>
                <LockIcon />
            </div>
            <h4 className={styles.title} dangerouslySetInnerHTML={{__html: hubSpotProps?.unlockRecipesTitle ?? 'Unlock more recipes'}} />
            <p className={styles.body} dangerouslySetInnerHTML={{__html: bodyText}}/>
            <button className={`btn btn-secondary`} onClick={handleOnButtonClicked}>{hubSpotProps?.unlockRecipesButtonLabel ?? 'Unlock now'}</button>
           </div>
        </>
    )
}

export default SignUpCard;