import React, { useEffect, useState } from "react";
import ICategory from "../../types/ICategory";
import CategoryList from "../category-list/category-list";
import ICategoryFilter from "../../types/ICategoryFilter";
import styles from './category-node.module.scss';
import categoryListStyles from '../category-list/category-list.module.scss';
import {ReactComponent as Caret} from '../../assets/icons/caret-down.svg';
import ICategoryNode from "../../types/ICategoryNode";
import { capitalizeFirstLetter } from "../../helpers/helper";

interface CategoryNodeProps {
    children: ICategory[];
    preSelectedFilters?: ICategoryFilter | null;
    node: ICategoryNode;
    tip?: string;
    disabled?: boolean;
    onSelectionChange: (parentId: number, selectedIds: number[]) => void;
}

const CategoryNode: React.FC<CategoryNodeProps> = ({ node, children, onSelectionChange, preSelectedFilters, tip, disabled = false }) => {
    const [collapsed, setCollapsed] = useState(node.Type !== 0);
    const [label, setLabel] = useState<string>('');
    const collapsible = node.Type !== 0;

    const onCategoryListChange = (selectedIds: number[]) => {
        onSelectionChange(node.Id, selectedIds);
    }

    const toggleOpen = () => {
        setCollapsed(prev => {
            if (collapsible)    
                return !prev
            return false;
        });
    }
    const onSelectedCategoriesLabelChanged = (label: string) => {
        setLabel(label);
    }

    useEffect(() => {
        const concatenatedLabel = children?.filter(child => preSelectedFilters?.selectedIds.some(id => id === child.Id))?.map(x => capitalizeFirstLetter(x.Title)).join(', ') || '';
        setLabel(concatenatedLabel);
    }, [children, preSelectedFilters])

    const setType = (type: number): "business" | "default" | "special" => {
        switch (type){
            case 0 : return "special";
            case 1: return "business";
            default: return "default";
        }
    }

    return (
        <div className={`${styles.container} ${collapsible ? styles.collapsible : ''} ${disabled ? styles.hidden : ''}`} style={{ order: node.Type === 0 ? 10 : node.Type }} >
            <div className={`${styles.header} `} onClick={toggleOpen}>
                <div className={styles.headline}>
                <span className={`${styles.name}`}>{node.Description ? node.Description : node.Title}</span>
                {collapsed && <span className={styles.label}>{label}</span>}
                </div>
                {collapsible && <Caret className={`${styles.caret} ${collapsed ? '': styles.rotate}`} />}
            </div>
            {!collapsed && 
            <>
                {!collapsible && 
                <div className={styles.subHeader}>
                    {/* <h6 className={styles.question}>{node.Description}</h6> */}
                    <span className={styles.tip}>{tip}</span>
                </div>}
                <CategoryList
                    className={categoryListStyles.verticalSpacingOff}
                    type={setType(node.Type)}
                    preselectedFilters={preSelectedFilters}
                    categories={children}
                    onSelectionChange={onCategoryListChange}
                    buildLabelOnChange={onSelectedCategoriesLabelChanged}
                    disabled={disabled} />
            </>
            }
        </div>
    )
}

export default CategoryNode;