import React, { useEffect, useState } from 'react';
import { IMenuMatcherPage } from '../../types/IMenuMatcherPage';
import ICategory from '../../types/ICategory';
import { IRecipeResponse } from '../../types/IRecipeResponse';
import { fetchData } from '../../helpers/fetchData';
import { useNavigate, useParams } from 'react-router-dom';
import BackgroundAnimation from '../../components/background-animation/background-animation';
import backgroundStyles from './../../components/background-animation/background-animation.module.scss';
import QuestionHeadline from '../../components/question-headline/question-headline';
import CategoryList from '../../components/category-list/category-list';
import { MenuComposerRequest } from '../../types/IMenuComposerRequest';
import { buildParamsQueryString, getCategoryRelatedQuestion } from '../../helpers/helper';
import ButtonContainer from '../../components/button-container/button-container';

interface KitchenTypeQuestionPageProps extends IMenuMatcherPage {
}

const KitchenTypeQuestionPage: React.FC<KitchenTypeQuestionPageProps> = ({ settings, goToHome }) => {
    const params = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState<IRecipeResponse<ICategory> | null>(null);
    const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
    const [paramsQueryString, setParamsQueryString] = useState<string>('');
    const [anySelected, setAnySelected] = useState(false);
    const [loading, setLoading] = useState(true);

    const handleSelectionChange = (selectedIds: number[]) => {
        setSelectedCategories(selectedIds);
    };
    
    useEffect(() => {
        const queryString = buildParamsQueryString(selectedCategories, params.businesses, params.occasions);
        setParamsQueryString(queryString);
    }, [params, selectedCategories])
    
    useEffect(() => {
        const apiUri = process.env.REACT_APP_API_KITCHENTYPES;
        const fetchCategoryData = async () => {
            
            const request = new MenuComposerRequest(params);
            const response = await fetchData<ICategory>(apiUri!, request);
            if (response.recipeResponse) {
                setData(response.recipeResponse);
            }
        };
        fetchCategoryData().then(() => {
            setLoading(false);
        }
    );;
}, [params, navigate, selectedCategories]);

useEffect(() => {
    if (selectedCategories.length > 0)
        setAnySelected(true);
    else
    setAnySelected(false);
}, [selectedCategories])


return (
    <>
            <BackgroundAnimation
                className={backgroundStyles.primary}
                backgroundAnimation={settings?.backgroundAnimation}
                logoUrl={settings?.logoUrl}
                loading={loading}
                loadingLogoUrl={settings?.alternateLogo}
                toolTips={settings?.tooltipLinks}
                animateOnPropChanged={selectedCategories}
                onLogoClick={goToHome}
                >
                {data && <div className='flex-container flex-column content-container'>
                    <QuestionHeadline step={3} labels={settings?.labels} question={data.Question} />
                    {data.Items?.length > 0 &&
                        <CategoryList
                            type='default'
                            categories={data.Items}
                            viewMoreButtonLabel={settings?.labels.viewMoreLabel}
                            onSelectionChange={handleSelectionChange} />}
                    <ButtonContainer
                        linkToNext={`/recipes/${paramsQueryString}`}
                        linkToNextLabel={settings?.labels?.viewResultsLabel ?? 'View Results'}
                        linkToPrev={`/question2/${params.businesses}`}
                        nextDisabled={!anySelected}
                        delimiter={true}
                    />
                </div>}
            </BackgroundAnimation>
        </>
    );
}

export default KitchenTypeQuestionPage;