import React from "react";
import styles from './tags.module.scss';

interface TagsProps {
    title: string;
    tags: string[]
}

const Tags:React.FC<TagsProps> = ({title, tags}) => {
    return (
        <>
            <div className={styles.container}>
                <h5 className={styles.title}>{title}</h5>
                <div className={styles.tags}>
                {tags.map(tag => 
                    <span key={tag} className={styles.tag}>{tag}</span>
                )}
                </div>
            </div>
        </>
    )
}

export default Tags;