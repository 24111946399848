import React from "react";
import styles from './product-card.module.scss';
import { IFoodProduct } from "../../types/IFoodProduct";
import ImageContainer from '../image/image-container';
import { Link } from "react-router-dom";
import imageStyles from '../image/image-container.module.scss';



interface ProductCardProps {
    className?: string;
    product: IFoodProduct;
    viewProductLabel?: string;
    piecesPerCartonLabel?: string;
}

const ProductCard: React.FC<ProductCardProps> =
    ({
        className,
        product,
        viewProductLabel = 'View Product',
        piecesPerCartonLabel = "pieces per carton" }) => {
        return (
            <div className={`${styles.container} ${className}`}>
                <ImageContainer className={`${className?.includes(styles.print)? imageStyles.print : imageStyles.large} ${imageStyles.product}`} overlayColor="bermuda-gray" url={product.ImageUrl} alt={product.Title} />
                <div className={styles.bodyContainer}>
                    <div className={styles.body}>
                        <h5 className={styles.title}>{product.Title}</h5>
                        {product.PiecesPerCarton && <span className={styles.piecesPerCarton}>{product.PiecesPerCarton} {piecesPerCartonLabel}</span>}
                    </div>
                    <Link to={product.Url}>
                        <button className={`btn btn-secondary ${styles.productButton}`}>{viewProductLabel}</button>
                    </Link>
                </div>
            </div>
        )
    }

export default ProductCard;
