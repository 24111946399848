import React from 'react';
import BackgroundAnimation from '../../components/background-animation/background-animation';
import styles from './landingpage.module.scss';
import backgroundStyles from '../.././components/background-animation/background-animation.module.scss';
import { IMenuMatcherPage } from '../../types/IMenuMatcherPage';
import ButtonContainer from '../../components/button-container/button-container';
import USPList from '../../components/usp-list/usp-list';
import DOMPurify from 'dompurify';

interface LandingPageProps extends IMenuMatcherPage {
}

const LandingPage: React.FC<LandingPageProps> = ({ settings, goToHome }) => {
    const labels = settings?.labels;
    const sanitizedHTML = DOMPurify.sanitize(settings?.welcomeHeadline ?? '');
    return (
        <>
            <BackgroundAnimation
                className={`${backgroundStyles.primary} ${styles.landingPage}`}
                loadingLogoUrl={settings?.alternateLogo}
                backgroundAnimation={settings?.backgroundAnimation}
                useBackgroundFade={true}
                flexibleBody={true}
                loading={false}
                toolTips={settings?.tooltipLinks}
                onLogoClick={goToHome}
            >
                <div className='flex-container flex-column'>
                    {settings?.logoUrl && (
                        <div className={styles.logoContainer}>
                            <img className={styles.logo} src={settings.logoUrl} alt="Logo" />
                        </div>
                    )}
                    <div className={styles.body}>
                        <h4 className={styles.headline}  dangerouslySetInnerHTML={{ __html: sanitizedHTML }}></h4>
                        <USPList list={settings?.welcomeUSP} />
                    </div>
                    <ButtonContainer linkToNext='/question1' linkToNextLabel={labels?.startButtonLabel ?? 'Start Matching'}></ButtonContainer>
                </div>
            </BackgroundAnimation>
        </>

    );
}

export default LandingPage;
