import React, { CSSProperties, useEffect, useState } from "react";
import styles from './progress-bar.module.scss';
import { insertValueInTemplate } from "../../helpers/helper";

interface ProgressBarProps {
    templateLabel?: string;
    step: number;
    totalSteps: number;
}

const ProgressBar:React.FC<ProgressBarProps> = ({templateLabel, step, totalSteps}) => {
    const [progress, setProgress] = useState<CSSProperties>({});

    useEffect(() => {
        if (totalSteps !== 0){
            const percentage = step / totalSteps * 100;
            setProgress({
                width: `${percentage}%`
            })
        }
    }, [step, totalSteps])

    let progressLabel = insertValueInTemplate(templateLabel, step);
    progressLabel = insertValueInTemplate(progressLabel, totalSteps, 1 )

    return (
        <div className={styles.container}>
            {progressLabel && <span className={styles.label}>{progressLabel}</span>}
            <div className={styles.progressContainer}>
                <div className={styles.progress} style={progress}></div>
            </div>
        </div>
    )
}

export default ProgressBar;