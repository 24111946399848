import React from "react";
import styles from './checkbox.module.scss'
import { ReactComponent as CheckIcon } from './../../assets/icons/check.svg';

interface CheckBoxProps {
    checked: boolean;
    className?: string;
    id?: number;
    title?: string;
    useAsVisualElement?: boolean;
    disabled?: boolean;
}

const CheckBox: React.FC<CheckBoxProps> = ({checked, id, title, useAsVisualElement = false, className, disabled = false }) => {
return (
<>
<div className={`${styles.container} ${checked ? `${styles.checked}` : ''} ${className}`}>
    {checked && <CheckIcon />}
    {!useAsVisualElement && <input disabled={disabled} type='checkbox' onChange={() => {}} checked={checked} name={id?.toString()} value={id} title={title}></input>}
</div>

</>
)}

export default CheckBox;
