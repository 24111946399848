import React, { useEffect, useRef, useState } from "react";
import styles from './progress-circle.module.scss';
import { CSSProperties } from "react";

interface ProgressCircleProps {
    step: number;
    totalSteps?: number;
}

const ProgressCircle: React.FC<ProgressCircleProps> = ({ step, totalSteps = 3 }) => {
    const circleRef = useRef<SVGCircleElement>(null);
    const [progress, setProgress] = useState<CSSProperties>({});
    const [containerStyle, setContainerStyle] = useState<CSSProperties>({});

    useEffect(() => {
        const percentage = 1 - (step / totalSteps);
        const radius = parseInt(circleRef.current?.getAttribute('stroke-dasharray') ?? '');
        let strokeWidth = parseInt(circleRef.current?.getAttribute('stroke-width') ?? '');
        if (isNaN(strokeWidth))
            strokeWidth = 6;

        if (!isNaN(radius)) {
            if (percentage > 0.98)
                setProgress({
                    display: 'none'
                });
            else if (percentage < 0.02)
                setProgress({});
            else
                setProgress({
                    strokeDashoffset: `${(percentage * radius) + strokeWidth}px`
                });

            //Set the beginning of the progress bar in top
            const rotationDegreesOffset = ((strokeWidth / 2) / radius) * 360;
            setContainerStyle({
                transform: `rotate(${270 + rotationDegreesOffset}deg)`
            });
        }

    }, [step, totalSteps])
    return (
        <>
            <div className={styles.container}>
                <div  className={styles.iconContainer}>
                    <svg style={containerStyle} width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="24" cy="24" r="20" stroke="#BFD0C9" strokeWidth="4" fill="none" />
                        <circle ref={circleRef} cx="24" cy="24" r="20" stroke="#759488" strokeWidth="4" fill="none"
                            strokeDasharray="125.66" strokeDashoffset="0" strokeLinecap="round" style={progress} />
                    </svg>
                </div>
                <span className={styles.counter}>{step}/{totalSteps}</span>
            </div>
        </>
    )
}
export default ProgressCircle;