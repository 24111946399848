import React, { CSSProperties, useEffect, useState } from "react";
import { IRecipe } from "../../types/IRecipe";

interface MatchTagProps {
    recipe: IRecipe;
    matchlabel?: string;
    className?: string;
    style?: CSSProperties;
}

const MatchTag: React.FC<MatchTagProps> = ({recipe, className, matchlabel="match", style = {}}) => {
    const [label, setLabel] = useState<string | undefined>(undefined);

    useEffect(() => {
            setLabel(`${recipe.MatchRate}% ${matchlabel}`)
    }, [recipe.MatchRate, matchlabel])

    if (recipe.MatchRate < 1) return (<></>);
    return (
        <span style={style} key={recipe.Id + "_match"} className={className}>{label}</span>
    );
}

export default MatchTag;