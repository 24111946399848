import React from 'react';
import { IRecipe } from '../../types/IRecipe';
import styles from './recipe-modal.module.scss';
import Accordion from '../accordion/accordion';
import { IAccordionItem } from '../../types/IAccordionItem';
import ProductCard from '../product-card/product-card';
import ImageContainer from '../image/image-container';
import ILabels from '../../types/ILabels';
import Tags from '../tags/tags';
import SlideOver from '../slide-over/slide-over';
import EmailTo from '../email-to/email-to';
import imageStyles from '../image/image-container.module.scss';
import { renderTagLabel } from '../../helpers/helper';
import MatchTag from '../match-tag/match-tag';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Print } from '../../assets/icons/print.svg';

interface RecipeModalProps {
    recipe: IRecipe;
    labels?: ILabels;
    unlocked?: boolean;
    onClose: () => void;
    onSendEmailClick: () => void;
}

const RecipeModal: React.FC<RecipeModalProps> = ({ recipe, labels, onClose, onSendEmailClick, unlocked = false }) => {
    const recipeImage = recipe.Images.find(x => x.Type === "Recipe");

    const accordionItems = [{ title: labels?.ingredientsLabel ? labels.ingredientsLabel : "Ingredients", text: recipe.Ingredients } as IAccordionItem]

    const closeModal = () => {
        onClose();
    }

    const onSendEmailClicked = (): void => onSendEmailClick();

    const printRecipe = () => {
        sessionStorage.setItem('printData', JSON.stringify({ recipe, labels }));
        window.open('/#/print', '_blank');
    }

    const getOrder = (value: number) => {
        return value === 0 ? value : value + 1;
    }

    return (
        <>
            <SlideOver onClose={closeModal} buttonBackLabel={labels?.backLabel}>
                <ImageContainer className={imageStyles.large} url={recipeImage?.RelativeUrl} alt={recipe.Name}>
                    <button title='Print recipe' className={`btn btn-small btn-ghost ${styles.printButton}`} onClick={printRecipe}>
                        <Print className={styles.printIcon} />
                    </button>
                </ImageContainer>
                <div className={styles.bodyContainer}>
                    <div className={styles.body}>
                        <h5 className={styles.title}>{recipe.Name}</h5>
                        <div className={styles.infoContainer}>
                            <MatchTag matchlabel={labels?.matchLabel} style={{ order: '1' }} recipe={recipe} className={styles.info} />
                            {recipe.EmphasizedCategories?.map((category, index) =>
                                <span key={category.Id} className={styles.info} style={{ order: `${getOrder(index)}` }}>{renderTagLabel(category)}</span>
                            )}
                        </div>
                        {recipe.Ingredients && <Accordion items={accordionItems} />}
                    </div>
                </div>
                <div className={styles.instructionsContainer} >
                    {recipe.Instructions && recipe.Instructions.map((instructions, c_index) =>
                        <>
                            {instructions.Instruction &&
                                <>
                                    <span key={`heading${c_index}`} className={styles.instructionsHeading}>{instructions.Heading ? instructions.Heading : labels?.instructionsLabel ?? "Instructions"}</span>
                                    <ol className={styles.instructionList} key={instructions.Heading + c_index}>
                                        {instructions.Instruction.map((instruction, i_index) =>
                                            <li className={styles.instruction} key={i_index}>
                                                {instruction.Short}
                                            </li>
                                        )}
                                    </ol>
                                </>}
                        </>)}
                </div>
                {!unlocked && <EmailTo label={labels?.mailRecipeLabel} onSendEmailClick={onSendEmailClicked} />}
                {recipe.RelatedProducts?.length > 0 &&
                    <>
                        <h5 className={`${styles.productsHeading} ${unlocked ? styles.unlocked : ''}`}>{labels?.productsLabel ?? 'Products'}</h5>

                        {recipe.RelatedProducts.map((product) =>
                            <ProductCard
                                key={product.Id}
                                product={product}
                                viewProductLabel={labels?.viewProductLabel}
                                piecesPerCartonLabel={labels?.piecesPerCartonLabel}
                            />)}

                    </>}
                {recipe.Categories?.length > 0 &&
                    <Tags tags={[...recipe.Categories.map(x => x.Description ? x.Description : x.Title)]} title={labels?.tagsLabel ?? 'Tags'}></Tags>
                }
            </SlideOver>
        </>
    )
}

export default RecipeModal;