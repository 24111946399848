import React, { CSSProperties } from 'react';
import { IRecipe } from '../../types/IRecipe';
import styles from './recipe-card.module.scss';
import ImageContainer from '../image/image-container';
import { renderTagLabel } from '../../helpers/helper';
import MatchTag from '../match-tag/match-tag';
interface RecipeCardProps {
    recipe: IRecipe;
    onButtonClick: (clickedId: number) => void;
    viewRecipeLabel?: string;
    matchLabel?: string;
    rank?: string;
}

const RecipeCard: React.FC<RecipeCardProps> = ({ recipe, onButtonClick, viewRecipeLabel, rank, matchLabel }) => {
    const recipeImage = recipe.Images.find(x => x.Type === "Recipe");

    const buttonClicked = () => {
        onButtonClick(recipe.Id);
    }

    const getOrder = (value: number) => {
        return value === 0 ? value : value+1;
    }

    return (
        <>
            <div className={styles.container}>
               <ImageContainer url={recipeImage?.RelativeUrl} alt={recipe.Name}>
                    {rank && <span className={styles.rank}>{rank}</span>}
                    </ImageContainer>
                <div className={styles.bodyContainer}>
                    <div className={styles.body}>
                        <h5 className={styles.title}>{recipe.Name}</h5>
                        <span className={styles.productTitle}>{recipe.RelatedProducts[0]?.Title}</span>
                        <div className={styles.infoContainer}>
                            <MatchTag matchlabel={matchLabel} style={{order: '1'} as CSSProperties} recipe={recipe} className={styles.info} />
                            {recipe.EmphasizedCategories?.map((category, index) => 
                                <span key={category.Id} className={styles.info} style={{order: `${getOrder(index)}`}}>{renderTagLabel(category)}</span>
                            )}
                        </div>
                    </div>
                    <button className='btn btn-secondary' onClick={buttonClicked}>{viewRecipeLabel ?? "View recipe"}</button>
                </div>
            </div>
        </>
    )
}

export default RecipeCard;