import React, { useEffect, useRef } from "react";
import styles from './recipe-skeleton.module.scss';
import { ReactComponent as LockIcon } from '../../assets/icons/lock.svg';
import ImageContainer from "../image/image-container";
import imageStyles from '../image/image-container.module.scss';

interface RecipeSkeletonProps {
    imageUrl?: string;
}

const RecipeSkeleton: React.FC<RecipeSkeletonProps> = ({ imageUrl }) => {
    return (
        <>
            <div className={styles.container}>
                <ImageContainer className={imageStyles.skeleton} url={imageUrl ?? ''} alt="skeleton">
                    <span className={styles.lock}><LockIcon /></span>
                </ImageContainer>
                <div className={styles.bodyContainer}>
                    <div>
                        <span className={styles.full}></span>
                    </div>
                    <div>
                        <span className={styles.half}></span>
                    </div>
                    <div>
                        <span className={`${styles.full} ${styles.small}`}></span>
                    </div>
                    <div>
                        <span className={`${styles.sixth} ${styles.small}`}></span>
                        <span className={`${styles.third} ${styles.small}`}></span>
                        <span className={`${styles.third} ${styles.small}`}></span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RecipeSkeleton;