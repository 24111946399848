import { IRecipeResponse } from "../types/IRecipeResponse";
import IResponse from "../types/IResponse";
import axios from 'axios';
import { MenuComposerRequest } from "../types/IMenuComposerRequest";


export const fetchData = async <T extends {}>(apiUri: string, request: MenuComposerRequest | null = null): Promise<IResponse<T>> => {
    try {
        const language = document.documentElement.lang;
        apiUri = apiUri + `?lang=${language}`
        if (request) {
            const response = await axios.post<IRecipeResponse<T>>(apiUri, request, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return { recipeResponse: response.data, error: null };
        }
        else {
            const response = await axios.get<IRecipeResponse<T>>(apiUri);   
            return { recipeResponse: response.data, error: null };
        }
    } catch (error) {
        let errorMessage = 'An unknown error occurred';
        if (axios.isAxiosError(error)) {
            errorMessage = error.response?.data?.message || error.message;
        } else if (error instanceof Error) {
            errorMessage = error.message;
        }
        console.error('Error fetching data:', errorMessage);
        return { recipeResponse: null, error: errorMessage };
    }
};

export const getApiUriFromStep = (step:Number):string => {
    switch (step) {
        case 1:
            return process.env.REACT_APP_API_BUSINESSES ?? '';
        case 2:
            return process.env.REACT_APP_API_OCCASIONS ?? '';
        case 3:
            return process.env.REACT_APP_API_KITCHENTYPES ?? '';
        default:
            return '';
    }
    
}