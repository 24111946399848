import React, { useState } from "react";
import styles from './email-to.module.scss';
import { ReactComponent as Arrow } from '../../assets/icons/arrow-left.svg';

interface EmailToProps {
    label?: string;
    item?: any | null;
    onSendEmailClick: () => void;
}
const EmailTo: React.FC<EmailToProps> = ({ label, item = null, onSendEmailClick }) => {
    const [formOpen, setFormOpen] = useState(false);
    const [email, setEmail] = useState('');

    const onButtonClicked = () => {
        if (item !== null)
            setFormOpen(true);
        else
            onSendEmailClick();
    }

    const handleSubmit = () => {
        //Post to API
        //.then
        setFormOpen(false);
        setEmail('');
    }

    return (
        <>
            <div className={styles.container} >
                {!formOpen && <button onClick={onButtonClicked} className={`${styles.mailMeButton} btn`}>{label}</button>}
                {formOpen && <form className={`${styles.form}`} onSubmit={handleSubmit}>
                    <label className={styles.label}>
                        <span className={styles.title}>Email</span>
                        <input
                            className={styles.input}
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            />
                        </label>
                    <button className={styles.button} type="submit"><Arrow className={styles.arrow} /></button>
                </form>}
            </div>
        </>
    )
}
export default EmailTo;