import { Params } from "react-router-dom";

export interface IMenuComposerRequest {
    Filters: number[][] | null;
  
}

export class MenuComposerRequest implements IMenuComposerRequest {
    Filters: number[][] | null = null;
    
    constructor(params: Params<string>) {
        this.Filters = [];
        if (params.businesses) {
            this.pushParamsArray(params.businesses);
        }

        if (params.occasions){
           this.pushParamsArray(params.occasions);
        }
            
        if (params.kitchenTypes) {
            this.pushParamsArray(params.kitchenTypes);
        }
    }

    getParamsArray = (param?: string): number[] | null => {
        return param?.split(',').map(Number) || null
    }

    pushParamsArray = (params?: string) => {
        const paramsArray = this.getParamsArray(params);
        if (paramsArray)
            this.Filters?.push(paramsArray);
    }
}