import React, { ReactNode, useEffect, useState } from "react";
import { ReactComponent as Overlay } from '../../assets/icons/image-overlay.svg'
import { ReactComponent as NoImage } from '../../assets/icons/no-image.svg'
import styles from './image-container.module.scss';
import { checkImageURL } from "../../helpers/helper";


interface ImageContainerProps {
    url?: string;
    alt: string;
    overlayColor?: "transparent" | "white" | "bermuda-gray";
    children?: ReactNode;
    className?: string;
}

const ImageContainer: React.FC<ImageContainerProps> = ({ url, alt, children, overlayColor, className }) => {
    const [validImage, setValidImage] =  useState(false);
    const [imageBaseUrl, setImageBaseUrl] = useState<string | undefined>(process.env.REACT_APP_IMAGE_URL);

    const overlayClass = (color?: string) => {
        switch (color) {
            case 'transparent': return styles.transparent;
            case 'bermuda-gray': return styles.bermudaGray;
            default: return styles.white;
        }
    }

    useEffect(() => {
        checkImageURL(`${imageBaseUrl}${url}`)
            .then(isValid => {
                if (isValid) {
                   setValidImage(true);
                }
            });
    }, [imageBaseUrl, url])

    useEffect(() => {
        if (className?.includes(styles.large))
            setImageBaseUrl(process.env.REACT_APP_IMAGE_LARGE_URL)
        
    }, [className])
    
    return (
        <div className={`${styles.container} ${className} ${!validImage ? styles.noImage : ''}`}>
            <Overlay className={`${styles.overlay} ${overlayClass(overlayColor)}`} />
            {validImage && <picture>
                    <source srcSet={`${imageBaseUrl}${url}`} />
                    <img className={styles.image} src={`${process.env.REACT_APP_IMAGE_URL}${url}`} alt={alt} />
                </picture>
                }
            {!validImage && <NoImage className={`${styles.image} ${styles.invalidImage}`} />}
            {children && children}
        </div>
    )
}

export default ImageContainer;
