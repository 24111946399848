import React, { useEffect, useRef, useState } from 'react';
import { IMenuMatcherSettings } from '../../types/IMenuMatcherSettings';
import axios from 'axios';
import ICategoryNode from '../../types/ICategoryNode';
import ICategoryFilter from '../../types/ICategoryFilter';
import { useNavigate, useParams } from 'react-router-dom';
import CategoryNode from '../category-node/category-node';
import styles from './filter-modal.module.scss';
import ProgressBar from '../progress-bar/progress-bar';
import { arraysEqual, getAdditionalFiltersString, joinFiltersOfType } from '../../helpers/helper';
import ICategory from '../../types/ICategory';

interface FilterModalProps {
    settings: IMenuMatcherSettings | null;
    additionalFilters?: ICategoryFilter[] | null;
    recipesCategories?: number[];
    open: boolean;
    pageLoaded: boolean;
    onSubmit: (filters: ICategoryFilter[]) => void;
    onClose: () => void;
    unlocked: boolean;
}

const FilterModal: React.FC<FilterModalProps> = ({onClose, settings, onSubmit, additionalFilters, recipesCategories, open, pageLoaded, unlocked}) => {
    const params = useParams();
    const navigate = useNavigate();
    const [categories, setCategories] = useState<ICategoryNode[] | null>(null);
    const [selectedFilters, setSelectedFilters] = useState<ICategoryFilter[] | null>(null);
    const [shouldRequest, setShouldRequest] = useState(false);
    const [nodesWithChecks, setNodesWithChecks] = useState(0);
    const listContainerRef = useRef<HTMLDivElement>(null);
    const [isMobile, setIsMobile] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [positionTop, setPositionTop] = useState<number | null>(null);
  
    useEffect(() => {
      const mediaQuery = window.matchMedia('(max-width: 768px)');
  
      setIsMobile(mediaQuery.matches);
  
      const handleMediaQueryChange = (e:any) => {
        setIsMobile(e.matches);
      };
  
      mediaQuery.addEventListener('change', handleMediaQueryChange);
  
      return () => {
        mediaQuery.removeEventListener('change', handleMediaQueryChange);
      };
    }, []);
  
    useEffect(() => {
        if (isMobile && pageLoaded) {
            setIsOpen(open)
        }
        else {
            setIsOpen(pageLoaded);
        }
        
    }, [isMobile, open, pageLoaded])

    const handleSelectionChange = (parentId: number, selectedIds: number[]) => {
        const currentFilter = selectedFilters?.find(x => x.parentId === parentId);
      
        if (currentFilter?.type !==0 && !arraysEqual(currentFilter?.selectedIds, selectedIds)) setShouldRequest(true);

        setSelectedFilters(prev => {
            if (!prev) return [];

            return prev.map(filter => {
                if (filter.parentId === parentId) {

                    return { ...filter, selectedIds };
                }
                return filter;
            });
        });
    }

    const hasValidCategories = (categories: ICategory[], reference?: number[]):boolean => {
        if (!categories || categories.length < 1) return false;
        else if (!reference || reference.length < 1) return true;
        return categories.filter(x => reference?.some(c => c === x.Id)).length > 0;
    }

    useEffect(() => {
        if (selectedFilters)
            setNodesWithChecks(selectedFilters.filter(x => x.selectedIds.length !== 0).length)
    }, [selectedFilters])

    useEffect(() => {
        const apiUri = process.env.REACT_APP_API_CATEGORIES + `?lang=${document.documentElement.lang}`;
        const fetchCategories = async () => {
            const response = await axios.get<ICategoryNode[]>(apiUri!);
            if (response.data) {
                const validData = response.data.filter(x => hasValidCategories(x.Children, recipesCategories)).map(x => {
                    if (x.Type === 0)
                    return {
                        Id: x.Id,
                        Title: x.Title,
                        Type: x.Type,
                        Children: x.Children.filter(x => recipesCategories?.some(c => c === x.Id)),
                        Description: x.Description
                    } as ICategoryNode
                    else return x;
                });
                setCategories(validData);
                setSelectedFilters(validData.map(x => ({
                    parentId: x.Id,
                    type: x.Type,   
                    selectedIds: []
                }) as ICategoryFilter));
            }
        }
        fetchCategories()
        .then(() => {
            const businessIds = params.businesses ? params.businesses.split(',').map(Number) : [];
            const occasionIds = params.occasions ? params.occasions.split(',').map(Number) : [];
            const kitchenTypeIds = params.kitchenTypes ? params.kitchenTypes.split(',').map(Number) : [];

            setSelectedFilters(prev => {
                if (!prev) return [];
        
                return prev.map(filter => {
                    if (filter.type === 1) {
                        return { ...filter, selectedIds: businessIds };
                    } else if (filter.type === 2) {
                        return { ...filter, selectedIds: occasionIds };
                    } else if (filter.type === 3) {
                        return { ...filter, selectedIds: kitchenTypeIds };
                    } 
                    else if (additionalFilters?.some(x => x.parentId === filter.parentId)) {
                        return { ...filter, selectedIds: additionalFilters?.filter(x => x.parentId === filter.parentId)?.flatMap(x => x.selectedIds) || []}
                    }

                    return filter;
                });
            });
            if (listContainerRef.current) {
                listContainerRef.current.scrollTop = 0; 
            }
            }
        );
    }, [additionalFilters, params, recipesCategories])

    const touchStartY = useRef(0);
    const touchEndY =  useRef(0);

    const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        touchStartY.current = e.touches[0].clientY;
    };
    
    const handleTouchEnd = () => {
        const swipeDistance = touchEndY.current - touchStartY.current;
        if (swipeDistance > 40) {
            closeButtonClicked();
        }
        setPositionTop(null);
    };
    
    const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
        touchEndY.current = e.touches[0].clientY;
        setPositionTop(touchEndY.current);
    };

    const closeButtonClicked = () =>{ 
        onClose();
    };


    const submitButtonClicked = () => {
        const additionalQueryString = getAdditionalFiltersString(selectedFilters);
        if (shouldRequest) {
            const businesses = joinFiltersOfType(selectedFilters, 1);
            const occasions = joinFiltersOfType(selectedFilters, 2);
            const kitchenTypes = joinFiltersOfType(selectedFilters, 3);
            navigate(`/recipes/${businesses}/${occasions}/${kitchenTypes}${additionalQueryString}`);
        }
        else {
            onSubmit(selectedFilters!);
        }
    }

    return (
        <>
        <div className={`${styles.overlay} ${isOpen ? '' : styles.closed}`} onClick={closeButtonClicked}></div>
        <div ref={listContainerRef} className={`${styles.container} ${isOpen ? '' : styles.closed}`}
         style={{
            top: positionTop !== null ? `${positionTop}px` : undefined, // Sätt bara top om positionTop är satt
            transition: positionTop === null ? 'top 0.2s ease-out' : 'none' // Använd övergång om den återgår till SCSS
        }}>
            <div className={styles.indicatorContainer} 
            onTouchStart={handleTouchStart} 
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            onDoubleClick={closeButtonClicked}
           >
                <div className={styles.indicator}></div>
            </div>
            <h5 className={styles.headline}>{settings?.labels.refineResultsLabel}</h5>
            {selectedFilters?.some(x => x.selectedIds?.length > 0) && (<ProgressBar 
                templateLabel={settings?.labels.questionProgressLabel}
                step={nodesWithChecks}
                totalSteps={selectedFilters.length}/>)}
            <div className={styles.categoriesContainer}>
                {isMobile && <div className={styles.separator}></div>}
                {categories && categories.map((node, index) =>
                    <>
                        {node.Children?.length > 0 && <CategoryNode
                            tip={settings?.labels.tipLabel}
                            key={node.Id}
                            node={node}
                            disabled={false}
                            preSelectedFilters={selectedFilters?.find(x => x.parentId === node.Id)}
                            children={node.Children}
                            onSelectionChange={handleSelectionChange} />}
                    </>)}
            </div>
        </div>
            <button className={`btn ${styles.btnSubmit} ${isOpen ? '' : styles.closed}`} onClick={submitButtonClicked}>{settings?.labels.viewResultsLabel}</button>
        </>
    )
}

export default FilterModal;