import React from 'react';
import ILabels from '../../types/ILabels';
import styles from './question-headline.module.scss';
import ProgressCircle from '../progress-circle/progress-circle';

interface QuestionHeadlineProps {
    labels?: ILabels;
    question?: string;
    step: number;
}

const QuestionHeadline: React.FC<QuestionHeadlineProps> = ({ labels, question, step }) => {
    return (
        <>
            <div className={styles.container}>
                <div className={styles.questionContainer}>
                    <span className={styles.questionLabel}>{labels?.questionLabel} {step}</span>
                    <h5 className={styles.questionHeadline}>{question}</h5>
                    <span className={styles.questionTip}>{labels?.tipLabel}</span>
                </div>
                <div className={styles.progressContainer}>
                    <ProgressCircle step={step} />
                </div>
            </div>
        </>
    )
}

export default QuestionHeadline;