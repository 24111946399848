import React, { useEffect, useState } from "react";
import styles from './loading.module.scss';

interface LoadingProps {
    logoUrl: string;
    text?: string;
}

const Loading: React.FC<LoadingProps> = ({ logoUrl, text }) => {
    const [dots, setDots] = useState<string>('.');

    useEffect(() => {
        const timer = setTimeout(() => {
            if (dots.length === 4)
                setDots('.');
            else 
                setDots(prev => prev + '.');
        }, 300)
        return () => clearTimeout(timer); 
    }, [dots])

    return (
        <>
            <div className={styles.container}>
                <div className={styles.content}>
                    <img className={styles.logo} src={logoUrl} alt="Logo" />
                    {text && <h6 className={styles.text}>{text}<span className={styles.dots}>{dots}</span></h6>}
                </div>
            </div>
        </>
    )
}

export default Loading;