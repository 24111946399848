import React from "react";
import styles from './progress-dots.module.scss';

interface ProgressDotsProps {
    step: number;
    totalSteps: number;
}

const ProgressDots: React.FC<ProgressDotsProps> = ({ step, totalSteps }) => {
    return (
        <span className={styles.container}>
            {Array.from({ length: totalSteps }, (_, i) => (
                <span
                    key={i}
                    className={`${styles.dot} ${i < step ? styles.filled : ''}`}
                ></span>
            ))}
        </span>
    );
}

export default ProgressDots;