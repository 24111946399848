import React, { useEffect, useRef, useState } from "react";
import styles from './category-list.module.scss';
import ICategory from "../../types/ICategory";
import CategoryListItem from "../category-list-item/category-list-item";
import { ReactComponent as CaretDown } from '../../assets/icons/caret-down.svg';
import ICategoryFilter from "../../types/ICategoryFilter";
import { capitalizeFirstLetter } from "../../helpers/helper";

interface CategoryListProps {
    className?: string;
    type?: "default" | "business" | "special";
    categories: ICategory[];
    viewMoreButtonLabel?: string;
    preselectedFilters?: ICategoryFilter | null;
    onSelectionChange: (selectedIds: number[]) => void;
    buildLabelOnChange?: (label: string) => void;
    disabled?: boolean;
}

const CategoryList: React.FC<CategoryListProps> = ({ className, type = "default", categories, onSelectionChange, viewMoreButtonLabel, preselectedFilters, buildLabelOnChange, disabled }) => {
    const [selectedIds, setSelectedIds] = useState<number[]>(preselectedFilters ? preselectedFilters.selectedIds : []);
    const [viewMoreClicked, setViewMoreClicked] = useState(false);
    const [isOverflowed, setIsOverflowed] = useState(false);
    const [displayViewMore, setDisplayViewMore] = useState(false);
    const listRef = useRef<HTMLUListElement>(null);

    const onViewMoreClicked = () => {
        setDisplayViewMore(false);
        setViewMoreClicked(true);
    }
    
    const handleSelectionChange = (id: number, checked: boolean) => {
        const updatedSelectedIds = checked
            ? [...selectedIds, id]
            : selectedIds.filter(selectedId => selectedId !== id);

        setSelectedIds(updatedSelectedIds);
        onSelectionChange(updatedSelectedIds);

    };

    useEffect(() => {
        if (buildLabelOnChange) {
            const label = categories?.filter(x => selectedIds.some(id => id === x.Id))?.map(x => capitalizeFirstLetter(x.Title)).join(', ') || '';
            buildLabelOnChange(label);
        }

    }, [buildLabelOnChange, categories, selectedIds])

    useEffect(() => {
        const checkOverflow = () => {
            const listElement = listRef.current;
            if (listElement) {
                setIsOverflowed(listElement.scrollHeight > listElement.clientHeight || listElement.scrollWidth > listElement.clientWidth);
            }
        };

        checkOverflow();

        window.addEventListener("resize", checkOverflow);
        return () => {
            window.removeEventListener("resize", checkOverflow);
        };
    }, [categories]);

    useEffect(() => {
        setDisplayViewMore(type === "default" && isOverflowed);
    }, [isOverflowed, type]);

    const setTypeClassName = (type: string) => {
        switch (type) {
            case "business": return `${styles.categoryListBusiness} ${styles.scrollable}`;
            case "special": return styles.categoryListSpecial;
            default: return viewMoreClicked ? styles.scrollable : '';
        }
    }

    return (
        <>
            <div className={`${styles.container} ${className && className}`}>
                <ul ref={listRef} className={`${styles.categoryList} ${setTypeClassName(type)}`}>
                    {categories && categories.map((category) =>{
                        const isChecked = preselectedFilters?.selectedIds.some(filter => 
                            filter === category.Id
                        ) ?? false;
                        return(
                        <CategoryListItem
                            disabled={disabled}
                            key={category.Id}
                            type={type}
                            category={category}
                            onSelectionChange={handleSelectionChange}
                            preSelected={isChecked}
                        />)}
                    )}
                </ul>
                {displayViewMore &&
                    <div className={`${styles.viewMore} ${viewMoreClicked ? `${styles.hide}` : ''}`}>
                        <button onClick={onViewMoreClicked} className={styles.viewMoreButton}>{viewMoreButtonLabel ?? "View more"}<CaretDown className={styles.caret} /></button>
                    </div>}
            </div>
        </>
    )
}

export default CategoryList;