import React, { useState } from "react";
import ICategory from "../../types/ICategory";
import styles from './category-list-item.module.scss';
import Checkbox from "../checkbox/checkbox";

interface CategoryListItemProps {
    className?: string;
    type: string;
    category: ICategory;
    onSelectionChange: (id: number, checked: boolean) => void;
    preSelected?: boolean;
    disabled?: boolean;
}

const CategoryListItem: React.FC<CategoryListItemProps> = ({className, category, onSelectionChange, preSelected=false, type, disabled = false}) => {
    const [checked, setChecked] = useState(preSelected);
    
    const handleClick = () => {
        if (!disabled){
            const prevChecked = !checked;
            setChecked(prevChecked);
            onSelectionChange(category.Id, prevChecked);
        }
    }

    const setTypeClassName = (type: string) => {
        switch (type) {
            case "business": return styles.imageStyle;
            case "special": return `${styles.buttonStyle} ${styles.reverseOrder}`;
            default: return styles.buttonStyle;
        }
    }

    return (
        <>
            <li className={`${styles.listItem} ${className ? className : ''} ${setTypeClassName(type)} ${checked ? styles.checked : ''}`} onClick={handleClick}>
                {category.ThumbnailUrl &&<img className={styles.thumbnail} src={`${process.env.REACT_APP_CATEGORY_THUMBNAIL_URL}${category.RelativeThumbnailUrl}`} alt='Category' />}
                <label className={styles.listItemLabel} htmlFor={category.Id.toString()}>{category.Description ? category.Description : category.Title}</label>
                <Checkbox disabled={disabled} className={styles.checkbox} checked={checked} id={category.Id} title={category.Title}/>
                <span className={styles.description}>{category.Description ? category.Title : ''}</span>
            </li>
        </>
    )
}

export default CategoryListItem;