import { URLSearchParams } from "url";
import ICategoryQuestion from "../types/ICategoryQuestion";
import { IRecipe } from "../types/IRecipe";
import ICategoryFilter from "../types/ICategoryFilter";
import { ReactElement, ReactNode } from "react";
import {ReactComponent as Euro } from '../assets/icons/euro.svg';
import {ReactComponent as Timer } from '../assets/icons/timer.svg';
import ProgressDots from "../components/progress-dots/progress-dots";
import ICategory from "../types/ICategory";

export const getCategoryKey = (step: number): string => {
  switch (step) {
    case 1: return 'businesses';
    case 2: return 'occasions';
    case 3: return 'kitchentypes';
    default: return '';
  }
}

export const buildParamsQueryString = (selectedCategories: number[], businesses: string | null = null, occasions: string | null = null): string => {
  let queryString = '';
  if (businesses) {
    queryString += `${businesses}/`;
  }
  if (businesses && occasions) {
    queryString += `${occasions}/`;
  }

  return queryString + selectedCategories.join(',');
}

export const isHubSpotForm = (scriptString: string) => {
  return scriptString.includes('hbspt.forms.create');
}

export const insertValueInTemplate = (template: string | undefined, value: number | null, index: number = 0) => {
  if (!template) return undefined;
  const regex = new RegExp(`\\{${index}\\}`, 'g');
  return template.replace(regex, value != null ? value.toString() : '');
};


export const isUnlocked = (params: URLSearchParams) => {
  if (hasCookie()){

    setCookie("mm_key", process.env.REACT_APP_UNLOCKED_KEY);
    return true;
  }
  else if (params.get('key') === process.env.REACT_APP_UNLOCKED_KEY) return true;
  else
    return false;
}

export const getFiltersFromParams = (params: URLSearchParams): ICategoryFilter[] => {
  const paramsArray = params.get('filters')?.split(';');
  const filters = [] as ICategoryFilter[];
  paramsArray?.forEach(param => {
    let filter = {} as ICategoryFilter;
    let parentId = Number(param.split('|')[0]);
    let selectedIds = param.split('|')[1]?.split(',').map(x => Number(x)).filter(x => !isNaN(x));
    filter.parentId = isNaN(parentId) ? 0 : parentId;
    filter.selectedIds = selectedIds;
    filter.type = 0;
    filters.push(filter);
  })

  return filters;
}

export const checkImageURL = (url: string, timeout = 5000, retries = 3): Promise<boolean> => {
  return new Promise((resolve) => {
    const attempt = (remainingRetries: number) => {
      const img = new Image();
      const timer = setTimeout(() => {
        if (remainingRetries > 0) {
          attempt(remainingRetries - 1); 
        } else {
          resolve(false); 
        }
      }, timeout);

      img.onload = () => {
        clearTimeout(timer);
        resolve(true);
      };

      img.onerror = () => {
        clearTimeout(timer);
        if (remainingRetries > 0) {
          attempt(remainingRetries - 1);
        } else {
          resolve(false);
        }
      };

      img.src = url;
    };

    attempt(retries);
  });
};


export const getCategoryRelatedQuestion = (id: number, questions?: ICategoryQuestion[]): string | undefined => {
  return id !== 0 ? questions?.find(x => x.categoryId === id)?.question || undefined : undefined;
}

export const getArrayLengthByDuplication = (urls: any[] | undefined, newLength: number) => {
  if (!urls) return [];
  let concatenatedUrls = urls.slice();
  while (concatenatedUrls.length < newLength)   
      concatenatedUrls = concatenatedUrls.concat(urls);
  return concatenatedUrls.slice(0, newLength);
}

export const filterRecipes = (unfilteredItems: IRecipe[] | undefined, filters: ICategoryFilter[] | null): IRecipe[] => {
  const selectedIds = filters?.filter(x => x.type === 0).flatMap(x => x.selectedIds) ?? [];
  const filteredItems = unfilteredItems?.filter(recipe => 
      selectedIds.every(selectedId => recipe.Categories.some(category => category.Id === selectedId))
  ) ?? [];
  
  return filteredItems;
}

export const joinFiltersOfType = (filters: ICategoryFilter[] | null, type?: number): string | undefined => 
  filters?.filter(x => x.type === (type ?? 0)).flatMap(x => x.selectedIds)?.join(',') || undefined;

export const getAdditionalFiltersString = (filters: ICategoryFilter[] | null): string => {
  let filterStrings = [] as string[];
  filters?.filter(x => x.type === 0).forEach(filter => {
    if (filter.selectedIds.length > 0)
      filterStrings.push(`${filter.parentId}|${filter.selectedIds.join(',')}`); 
  })
  return filterStrings.length > 0 ? `?filters=${filterStrings.join(';')}`:  '';
}

export const arraysEqual = (array?: number[], comparison?: number[]): boolean => {
  if (!array || !comparison) return false;
  return array.length === comparison.length &&
        array.slice().sort().every((value, index) => value === comparison.slice().sort()[index]);
}

export const setCookie = (name: string, value?: string) => {
  const date = new Date();
  date.setTime(date.getTime() + (90 * 24 *60 *60 *1000));
  document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
}

const hasCookie = (): boolean => {
  const keyValue = getCookie('mm_key');
  return keyValue === process.env.REACT_APP_UNLOCKED_KEY;
}

const getCookie = (name: string): string | null => {
  const cookieArr = document.cookie.split(';');
  
  for (let cookie of cookieArr) {
    const [cookieName, cookieValue] = cookie.trim().split('=');
    if (cookieName === name) {
      return decodeURIComponent(cookieValue);
    }
  }
  
  return null;
};

export const renderTagLabel = (category: ICategory): ReactElement => {
  if (category.Description.includes('€')) {
      const array = category.Description.split('').filter(x => x = '€');
      return <><Euro /><ProgressDots step={array.length} totalSteps={6} /></>
  }
  if (category.Title.includes('min')){
      return <><Timer /><span>{category.Title}</span></>
  }

  return <span>{category.Title}</span>;
}

export const capitalizeFirstLetter = (text:string): string => {
  if (!text) return "";
    return text.charAt(0).toUpperCase() + text.slice(1);
}
