import React, { useEffect, useRef } from "react";
import styles from './script-modal.module.scss';
import { IHubSpotProps } from "../../types/IHubSpotProps";
import SlideOver from "../slide-over/slide-over";

interface ScriptModalProps {
  open: boolean;
  script: IHubSpotProps;
  buttonBackLabel?: string;
  onClose: () => void;
  onRecipesUnlocked: () => void;
}

const ScriptModal: React.FC<ScriptModalProps> = ({ open, script, buttonBackLabel, onRecipesUnlocked, onClose }) => {
  const scriptContainerRef = useRef<HTMLDivElement>(null);
  const scriptRef = useRef<HTMLScriptElement>(null);

  const closeButtonClicked = () => {
    onClose();
  }

  useEffect(() => {
    const currentRef = scriptContainerRef.current;
    if (currentRef) {
      const hbsptScriptContent = `
                    hbspt.forms.create({
                        region: "${script.region}",
                    portalId: "${script.portalId}",
                    formId: "${script.formId}",
                    onFormSubmit: function() {
                    window.${onRecipesUnlocked.name}();
                    }
                    });`;

      const newScript = document.createElement('script');
      newScript.textContent = hbsptScriptContent;

      currentRef.appendChild(newScript);

      (window as any)[onRecipesUnlocked.name] = onRecipesUnlocked;
    }


    return () => {
      if (currentRef && currentRef.firstChild) {
        currentRef.removeChild(currentRef.firstChild);
      }
      delete (window as any)[onRecipesUnlocked.name];
    };
  }, [onRecipesUnlocked, script]);

  useEffect(() => {
    if (scriptRef.current && scriptRef.current.parentNode) {

      const newScript = document.createElement('script');

      newScript.defer = true;
      newScript.type = "text/javascript";
      newScript.src = script?.src ?? '//js.hsforms.net/forms/embed/v2.js';

      scriptRef.current.replaceWith(newScript);
    }

  }, [scriptRef, script]);

  return (
    <>
      <script ref={scriptRef}></script>
      {open && <SlideOver ghostCloseButton={false} onClose={closeButtonClicked} buttonBackLabel={buttonBackLabel}>
        <div className={styles.container}>
          <div className={styles.description}>
            <h5 className={styles.headline}>Sla op en ontgrendel meer recepten</h5>
            <p className={styles.body}>Alle 24 matchende recepten ontgrendelen? We sturen ze graag gratis per mail toe samen met seizoensgebonden inspiratie.</p>
          </div>
          <div className={styles.script} ref={scriptContainerRef}></div>
        </div>
      </SlideOver>}
    </>
  )
}

export default ScriptModal;