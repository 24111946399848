import { useEffect, useState } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import './App.scss';
import LandingPage from './pages/landingpage/landingpage';
import { IMenuMatcherSettings } from './types/IMenuMatcherSettings';
import { getMenuMatcherSettings } from './helpers/settings';
import RecipeListPage from './pages/recipe-list-page/recipe-list-page';
import BusinessesQuestionPage from './pages/question-one-page/business-question-page';
import OccasionQuestionPage from './pages/question-two-page/occasions-question-page';
import KitchenTypeQuestionPage from './pages/question-three-page/kitchentype-question-page';
import PrintPage from './pages/print-recipe-page/print-recipe-page';


function App() {
  const [settings, setSettings] = useState<IMenuMatcherSettings | null>(null);

  const goToHome = () => {
    window.location.href = '/';
  } 

  useEffect(() => {
    const fetchedSettings = getMenuMatcherSettings();
    setSettings(fetchedSettings);
  }, [])
  return (
    <div id='menu_matcher'>
    <Router>
      <Routes>
        <Route path='/' element={<LandingPage settings={settings} goToHome={goToHome} /> }/>
        <Route path="/print" element={<PrintPage />} />
        <Route path='/question1' element={<BusinessesQuestionPage settings={settings} goToHome={goToHome} />}/>
        <Route path='/question2/:businesses' element={<OccasionQuestionPage settings={settings} goToHome={goToHome} />}/>
        <Route path='/question3/:businesses/:occasions' element={<KitchenTypeQuestionPage settings={settings} goToHome={goToHome} />}/>
        <Route path='/recipes/:businesses/:occasions/:kitchenTypes' element={<RecipeListPage settings={settings} goToHome={goToHome}/>}/>
      </Routes>
    </Router>
    </div>
  );
}

export default App;
