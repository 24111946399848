import React from "react";
import CheckBox from "../checkbox/checkbox";
import styles from './usp-list.module.scss';


interface USPListProps {
    list?: string[];
}

const USPList: React.FC<USPListProps> = ({ list }) => {

    if (!list) {
        return (<></>)
    }

    return (
        <>
            <div className={styles.uspList}>
                {list.map((usp, index) =>
                    <div key={index} className={styles.uspItem}>
                        <CheckBox className={styles.checkbox} checked={true} useAsVisualElement={true} />
                        <span>{usp}</span>
                    </div>
                )}
            </div>
        </>
    )
}

export default USPList;