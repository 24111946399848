import React, { useState } from "react";
import { IAccordionItem } from "../../types/IAccordionItem";
import styles from './accordion-item.module.scss';
import {ReactComponent as Caret } from '../../assets/icons/caret-down.svg'
import DOMPurify from "dompurify";

interface AccordionItemProps {
    item: IAccordionItem;
    defaultState?: boolean;
    className?: string;
}
const AccordionItem: React.FC<AccordionItemProps> = ({ item, defaultState = false, className }) => {
    const [open, setOpen] = useState(defaultState);

    const sanitizedHTML = DOMPurify.sanitize(item.text ?? '');

    const handleClick = () => {
        setOpen(prev => !prev);
    }
    return (
        <>
           <li onClick={handleClick} className={`${styles.container} ${className}`}>
            <div className={styles.header}>
                <span className= {styles.title}>{item.title}</span>
                <Caret className={`${styles.caret} ${open ? styles.rotate : '' }`} />
                </div>
            <div dangerouslySetInnerHTML={{ __html: item.text }} className={`${styles.text} ${open ? '' : styles.hidden}`}></div>
           </li>
        </>
    )
}

export default AccordionItem;