import React, { useEffect, useState } from 'react';
import { IMenuMatcherPage } from '../../types/IMenuMatcherPage';
import ICategory from '../../types/ICategory';
import { IRecipeResponse } from '../../types/IRecipeResponse';
import { fetchData } from '../../helpers/fetchData';
import BackgroundAnimation from '../../components/background-animation/background-animation';
import backgroundStyles from './../../components/background-animation/background-animation.module.scss';
import QuestionHeadline from '../../components/question-headline/question-headline';
import CategoryList from '../../components/category-list/category-list';
import { buildParamsQueryString, getCategoryRelatedQuestion } from '../../helpers/helper';
import ButtonContainer from '../../components/button-container/button-container';

interface BusinessesQuestionPageProps extends IMenuMatcherPage {

}

const BusinessesQuestionPage:React.FC<BusinessesQuestionPageProps> = ({ settings, goToHome }) => {
    
    const [data, setData] = useState<IRecipeResponse<ICategory> | null>(null);
    const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
    const [anySelected, setAnySelected] = useState(false);
    const [paramsQueryString, setParamsQueryString] = useState<string>('');
    const [loading, setLoading] = useState(true);
    
    const handleSelectionChange = (selectedIds: number[]) => {
        setSelectedCategories(selectedIds);
    };
    
    useEffect(() => {
        const queryString = buildParamsQueryString(selectedCategories);
        setParamsQueryString(queryString);
    }, [selectedCategories])
    
    useEffect(() => {
        const apiUri = process.env.REACT_APP_API_BUSINESSES;
        const fetchCategoryData = async () => {
            const response = await fetchData<ICategory>(apiUri!);
            if (response.recipeResponse){
                setData(response.recipeResponse);
            }
        };
        fetchCategoryData().then(() => {
            setLoading(false);
        }
    );
}, [selectedCategories]);

useEffect(() => {
    if (selectedCategories.length > 0)
        setAnySelected(true);
    else
    setAnySelected(false);
}, [selectedCategories]);

return (
    <>
            <BackgroundAnimation
            className={backgroundStyles.primary}   
            backgroundAnimation={settings?.backgroundAnimation}
            logoUrl={settings?.logoUrl}
            loading={loading}
            loadingLogoUrl={settings?.alternateLogo}
            toolTips={settings?.tooltipLinks}
            animateOnPropChanged={selectedCategories}
            onLogoClick={goToHome}
            >
                {data && (<div className='flex-container flex-column content-container'>
                    <QuestionHeadline step={1} labels={settings?.labels} question={data.Question}/>
                    {data.Items.length > 0 && 
                        <CategoryList type={'business'} 
                        categories={data.Items} 
                        onSelectionChange={handleSelectionChange}
                        viewMoreButtonLabel={settings?.labels.viewMoreLabel}/>
                        }
                    <ButtonContainer 
                        linkToNext={`/question2/${paramsQueryString}`} 
                        linkToNextLabel={settings?.labels?.buttonNextLabel ?? 'Next question'}
                        nextDisabled={!anySelected}
                        delimiter={true}
                        />
                </div>)}
            </BackgroundAnimation>
        </>
    );
}

export default BusinessesQuestionPage;