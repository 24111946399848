import React, { useEffect, useRef, useState } from 'react';
import { IMenuMatcherPage } from '../../types/IMenuMatcherPage';
import { useLocation, useParams } from 'react-router-dom';
import BackgroundAnimation from '../../components/background-animation/background-animation';
import backgroundStyles from './../../components/background-animation/background-animation.module.scss';
import { IRecipeResponse } from '../../types/IRecipeResponse';
import { IRecipe } from '../../types/IRecipe';
import { MenuComposerRequest } from '../../types/IMenuComposerRequest';
import { fetchData } from '../../helpers/fetchData';
import RecipeCard from '../../components/recipe-card/recipe-card';
import RecipeModal from '../../components/recipe-modal/recipe-modal';
import styles from './recipe-list-page.module.scss';
import ScriptModal from '../../components/script-modal/script-modal';
import SignUpCard from '../../components/signup-card/signup-card';
import { IHubSpotProps } from '../../types/IHubSpotProps';
import { filterRecipes, getFiltersFromParams, insertValueInTemplate, isUnlocked, setCookie } from '../../helpers/helper';
import FilterModal from '../../components/filter-modal/filter-modal';
import ICategoryFilter from '../../types/ICategoryFilter';
import RecipeSkeleton from '../../components/recipe-skeleton/recipe-skeleton';

interface RecipeListPageProps extends IMenuMatcherPage {

}

const RecipeListPage: React.FC<RecipeListPageProps> = ({ settings, goToHome }) => {
    const params = useParams();
    const location = useLocation();
    const [data, setData] = useState<IRecipeResponse<IRecipe> | null>(null);
    const [additionalFilters, setAdditionalFilters] = useState<ICategoryFilter[] | null>(null);
    const [recipes, setRecipes] = useState<IRecipe[] | undefined>(undefined);
    const [recipesCount, setRecipesCount] = useState<number | null>(null);
    const [skeletons, setSkeletons] = useState<IRecipe[] | undefined>(undefined);
    const [recipeModalOpen, setRecipeModalOpen] = useState(false);
    const [scriptModalOpen, setScriptModalOpen] = useState(false);
    const [loadEffectDone, setLoadEffectDone] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [filterModalOpen, setFilterModalOpen] = useState(false);
    const [selectedRecipe, setSelectedRecipe] = useState<IRecipe | null>(null);
    const [loading, setLoading] = useState(true);
    const [unlocked, setUnlocked] = useState(false);
    const [totalHitsLabel, setTotalHitsLabel] = useState<string>('');
    const [totalMatchesLabel, setTotalMatchesLabel] = useState<string>('');
    const [invalidSearch, setInvalidSearch] = useState(false);
    const listContainerRef = useRef<HTMLDivElement>(null);
    const loadingDelay = 5000;

    const closeRecipeModal = () => setRecipeModalOpen(false);

    const onSendEmailClicked = () => {
        setRecipeModalOpen(false);
        setScriptModalOpen(true);
    }

    const toggleScriptModal = () => {
        setScriptModalOpen(prev => !prev)
    };

    const toggleFilterModal = () => setFilterModalOpen(prev => !prev);

    const onFiltersSubmitted = (filters: ICategoryFilter[]) => {
        setAdditionalFilters(filters.filter(x => x.type === 0));
        if (listContainerRef.current) {
            listContainerRef.current.scrollTop = 0;
            setFilterModalOpen(false);
        }
    }

    const openRecipeOnclick = (id: number) => {
        const recipe = data?.Items.find(recipe => recipe.Id === id) ?? null;
        setSelectedRecipe(recipe);
        setRecipeModalOpen(true);
    }

    const onRecipesUnlocked = () => {
        setCookie("mm_key", process.env.REACT_APP_UNLOCKED_KEY);
        setUnlocked(true);
        setScriptModalOpen(false);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoadEffectDone(true);
        }, loadingDelay)
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (!loading && loadEffectDone)
            setLoaded(true);
    }, [loadEffectDone, loading]);

    useEffect(() => {
        setLoading(true);
        setFilterModalOpen(false);
        const apiUri = process.env.REACT_APP_API_RECIPES;
        const fetchRecipeData = async () => {
            const request = new MenuComposerRequest(params);
            const response = await fetchData<IRecipe>(apiUri!, request);
            if (response.recipeResponse) {
                setData(response.recipeResponse);
            }
        };
        fetchRecipeData().then(() => {
            setLoading(false);
        }
        );
    }, [params]);

    useEffect(() => {
        const filteredRecipes = filterRecipes(data?.Items, additionalFilters);
        if (data && !unlocked && filteredRecipes.length < 3) {
            setInvalidSearch(true);
            const timer = setTimeout(() => {
                setInvalidSearch(false);
            }, 3000)
            return () => clearTimeout(timer);
        }
        else {
            setInvalidSearch(false);
            setRecipesCount(filteredRecipes?.length ?? null);
            setRecipes(unlocked ? filteredRecipes : filteredRecipes?.slice(0, 3));
        }
    }, [additionalFilters, data, location, unlocked]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const filters = getFiltersFromParams(searchParams);
        setUnlocked(isUnlocked(searchParams));
        setAdditionalFilters(filters);
    }, [location.search])

    useEffect(() => {
        if (data?.NumberOfHits) {
            const ofTotalTemplate = insertValueInTemplate(settings?.labels.ofTotalLabel ?? '', recipesCount);
            setTotalHitsLabel(ofTotalTemplate ?? '');
            
            const totalMatchesTemplate = recipesCount != null && recipesCount > 0 ? insertValueInTemplate(settings?.labels.totalMatchesLabel ?? '', recipesCount) : settings?.labels.noHitsLabel;
            setTotalMatchesLabel(totalMatchesTemplate ?? '')
        }
    }, [data, settings, recipesCount, totalHitsLabel]);

    useEffect(() => {
        if (data?.Items) {
            let lastTwo = [];
            if (data.Items.length > 1) {
                lastTwo = data.Items.slice(-2);
            }
            else
                lastTwo = [data.Items[0], data.Items[0]];

            setSkeletons(lastTwo);
        }
    }, [data])

    const topResultsLabel = insertValueInTemplate(settings?.labels.topResultsLabel, 3) ?? '';

    const getRecipeCategories = () => {
        return data?.Items?.flatMap(x => x.Categories.map(x => x.Id)) ?? [];
    }

    return (
        <>
            <FilterModal
                open={filterModalOpen}
                settings={settings}
                recipesCategories={getRecipeCategories()}
                onClose={toggleFilterModal}
                onSubmit={onFiltersSubmitted}
                additionalFilters={additionalFilters}
                pageLoaded={loaded}
                unlocked={unlocked} 
                />
            <BackgroundAnimation
                className={`${backgroundStyles.recipeList} ${loading ? backgroundStyles.loading : ''}`}
                backgroundAnimation={settings?.backgroundAnimation}
                loading={loading}
                logoUrl={settings?.logoUrl}
                loadingLogoUrl={settings?.alternateLogo}
                loadingText={settings?.labels.preparingResultsLabel}
                toolTips={settings?.tooltipLinks}
                loadingDelay={loadingDelay}
                onLogoClick={goToHome}
            >
                {recipes && <div ref={listContainerRef} className={styles.container}>
                    {!unlocked && <div className={styles.resultsHeading}>
                        <span className={styles.heading}>{settings?.labels.hereTheyAreLabel}</span>
                        <span className={styles.topResults} dangerouslySetInnerHTML={{ __html: topResultsLabel }}></span>
                        <span className={styles.total}>{totalHitsLabel}</span>
                    </div>}
                    {unlocked && <div className={styles.resultsHeading}>
                        <span className={styles.totalMatches} dangerouslySetInnerHTML={{ __html: totalMatchesLabel }}></span>
                    </div>}
                    {recipes.map((recipe, index) =>
                        <RecipeCard 
                        rank={(index + 1).toString()} 
                        viewRecipeLabel={settings?.labels.viewRecipeLabel} 
                        matchLabel={settings?.labels.matchLabel}
                        key={recipe.Id}
                         recipe={recipe} 
                         onButtonClick={openRecipeOnclick} />
                    )}
                    {!unlocked && <>
                        <SignUpCard hubSpotProps={settings?.hubSpotProps as IHubSpotProps} numberOfHits={recipesCount ?? 0} onButtonClicked={toggleScriptModal} />
                        {skeletons && skeletons.map((skeleton, index) =>
                            <RecipeSkeleton key={index} imageUrl={skeleton?.Images?.find(x => x.Type === "Recipe")?.Url ?? ''}/>
                        )
                        }
                    </>
                    }

                    <div className={styles.stickyContainer}>
                        <button onClick={toggleFilterModal} className={`btn`}>{settings?.labels.refineResultsLabel}</button>
                    </div>
                </div>
                }
            </BackgroundAnimation>
            <ScriptModal
                open={scriptModalOpen}
                buttonBackLabel={settings?.labels.backLabel}
                script={settings?.hubSpotProps as IHubSpotProps}
                onClose={toggleScriptModal}
                onRecipesUnlocked={onRecipesUnlocked} />
            {recipeModalOpen && selectedRecipe && (
                <RecipeModal
                    unlocked={unlocked}
                    onSendEmailClick={onSendEmailClicked}
                    recipe={selectedRecipe}
                    onClose={closeRecipeModal}
                    labels={settings?.labels} />)}
            <span className={`${styles.invalidSearch} ${invalidSearch ? styles.show : ''}`}>{settings?.labels.invalidSearchLabel ?? 'Invalid search'}</span>
        </>
    )
}

export default RecipeListPage;