import React from "react"
import { Link } from "react-router-dom";
import styles from './button-container.module.scss'
import { ReactComponent as PrevIcon } from './../../assets/icons/arrow-left.svg';

interface ButtonContainerProps {
    linkToNext: string;
    linkToNextLabel: string;
    linkToPrev?: string;
    nextDisabled?: boolean;
    delimiter?: boolean;
}

const ButtonContainer: React.FC<ButtonContainerProps> = ({ linkToNext, linkToNextLabel, linkToPrev, nextDisabled = false, delimiter = false }) => {
    return (
        <>
            <div className={`${styles.container} ${delimiter ? `${styles.borderTop}` : ''}`}>
                {linkToPrev &&
                    <Link className={styles.linkPrevious} to={linkToPrev}>
                        <button className={`btn btn-secondary ${styles.btnPrevious}`}><PrevIcon /></button>
                    </Link>}
                <Link className={`${styles.linkNext} ${nextDisabled ? styles.linkDisabled : ''}`} to={linkToNext}>
                    <button disabled={nextDisabled} className={`btn ${styles.btnNext}`}>{linkToNextLabel}</button>
                </Link>
            </div>
        </>
    )
}
export default ButtonContainer;